import React from 'react'
import loadable from '@loadable/component'
import { SEO, Layout, HeroBrand, Landing } from '../components/index'
const Scene = loadable(() => import('../components/webgl/Scene'))
// const Landing = loadable(() => import('../components/Landing'))

const IndexPage = () => {
  return (
    <Layout bg="primary" mh="min100vh">
      <SEO title={`Welcome`} />
      <div id="canvas">
        <Scene />
      </div>
      <HeroBrand />
      <Landing />
    </Layout>
  )
}

export default IndexPage
